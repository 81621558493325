import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import './App.css';
import NewNav from './components/atoms/Nav/index';
import HomePage from './components/pages/home';
import NewHomePage from './components/pages/homeNew/index';
import DonatePy from './components/pages/donate-py/ach-pay';
import TeamPage from './components/pages/team/index';
import ProjectPage from './components/pages/project/index';
import AboutPage from './components/pages/about';
import Donate from './components/pages/donate/index';
import LoginPage from './components/pages/login';
// (Other imports as needed)

const stripePromise = loadStripe("pk_live_51N7Ic3Ggg9gYWr4n6TL9CXi9wu7JNEBu23bn6D5VECgGMODVrfw2rrhCMSYdr3CaQRdlR15rkoSi61OCcLhqr9by00NpwsVBaO");

function App() {
  return (
    <>
      <Router>
        <NewNav />
        <Switch>
          <Route exact path="/" component={NewHomePage} />
          <Route path="/team" component={TeamPage} />
          <Route path="/aboutUs" component={AboutPage} />
          <Route path="/project" component={ProjectPage} />
          <Route path="/donate">
            <Elements stripe={stripePromise}>
              <Donate stripe={stripePromise} />
            </Elements>
          </Route>
          <Route path="/donate-payment">
            <Elements stripe={stripePromise}>
              <DonatePy stripe={stripePromise} />
            </Elements>
          </Route>
          {/* Add other routes as needed */}
        </Switch>
      </Router>
    </>
  );
}

export default App;
